export default [
  {
    path: '/dashboard',
    name: 'RSPT-dashboard',
    component: () => import('@/views/dashboard/RSPT/dashboard.vue'),
  },
  {
    path: '/workout-video-management',
    name: 'workout-video-management',
    component: () => import('@/views/pages/RSPT/videoManagement/workoutVideoManagement.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard',
          class:"breadcrumb-slash"
        },
        {
          text: 'Workout Video Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workout-pending-videos',
    name: 'workout-pending-videos',
    component: () => import('@/views/pages/RSPT/videoManagement/allVidoemange/WorkoutPending.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Pending Videos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Workout Video Management',
          to:'/workout-video-management'
        },
        {
          text: 'Pending Videos',
          active:true
        },
      ],
    },
  },
  {
    path: '/workout-MostWatched-videos',
    name: 'workout-MostWatched-videos',
    component: () => import('@/views/pages/RSPT/videoManagement/allVidoemange/WorkourMost.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Most Watched Videos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Workout Video Management',
          to:'/workout-video-management'
        },
        {
          text: 'Most Watched Videos',
          active:true
        },
      ],
    },
  },
  {
    path: '/workout-AllVideo-videos',
    name: 'workout-AllVideo-videos',
    component: () => import('@/views/pages/RSPT/videoManagement/allVidoemange/WorkoutAll.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'All Videos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Workout Video Management',
          to:'/workout-video-management'
          
        },
        {
          text: 'All Videos',
          active:true
        },
      ],
    },
  },
  {
    path: '/settings/allvideos',
    name: 'setting-AllVideo',
    component: () => import('@/views/pages/RSPT/Setting/Videos.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'All Hide Videos',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'Hide Videos',
          active:true
        },
      ],
    },
  },
  {
    path: '/settings/suspendedclient',
    name: 'setting-suspended-user',
    component: () => import('@/views/pages/RSPT/Setting/SuspenClient.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'All Suspended Customer',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'View All Suspended Customer',
          active:true
        },
      ],
    },
  },
  {
    path: '/settings/rejected-customers',
    name: 'setting-rejecter-customers',
    component: () => import('@/views/pages/RSPT/Setting/rejectedCustomer.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'All Rejected Customer',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'View All Rejected Customer',
          active:true
        },
      ],
    },
  },
  {
    path: '/settings/rejected-trainer',
    name: 'setting-rejecter-trainer',
    component: () => import('@/views/pages/RSPT/Setting/rejectedTrainer.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'All Rejected Trainer',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'View All Rejected Trainer',
          active:true
        },
      ],
    },
  },
  {
    path: '/settings/predesignworkout',
    name: 'setting-predesign-workout',
    component: () => import('@/views/pages/RSPT/Setting/predesignWorkout/Index.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Predesign Workout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'View Predesign Workout',
          active:true
        },
      ],
    },
  },
  {
    path: '/settings/audio',
    name: 'setting-audio',
    component: () => import('@/views/pages/RSPT/Setting/audio/Audio.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Audio',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'Audio',
          active:true
        },
      ],
    },
  },
  {
    path: '/settings/categories',
    name: 'setting-categories',
    component: () => import('@/views/pages/RSPT/Setting/Categories.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Categories',
      
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'categories',
          active:true
        },
      ],
    },
  },
  {
    path: '/music',
    name: 'setting-music',
    component: () => import('@/views/pages/RSPT/Setting/audio/Music.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Music',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/profile'
          
        },
        {
          text: 'Music',
          active:true
        },
      ],
    },
  },
  {
    path: '/add-music',
    name: 'setting-add-audio',
    component: () => import('@/views/pages/RSPT/Setting/audio/AddMusic.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Music',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/profile'
          
        },
        {
          text: 'Add-Music',
          active:true
        },
      ],
    },
  },
  {
    path: '/edit-music/:id',
    name: 'edit-music',
    component: () => import('@/views/pages/RSPT/Setting/audio/AddMusic.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Music',
          to:'/music',
        },
        {
          text: 'Edit Music',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/suspendedtrainer',
    name: 'setting-suspended-trainer',
    component: () => import('@/views/pages/RSPT/Setting/SuspenTrainer.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'All Suspended Trainer',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'View All Suspended Trainer',
          active:true
        },
      ],
    },
  },
  {
    path: '/supplement',
    name: 'supplement',
    component: () => import('@/views/pages/RSPT/supplement/supplement.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Supplement List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/workout',
    name: 'setting-workout',
    component: () => import('@/views/pages/RSPT/Setting/Workout/workout.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/admin/profile'
          
        },
        {
          text: 'Setting',
          to:'/admin/settings'
        },
        {
          text: 'workout',
          active:true
        },
      ],
    },
  },
  {
    path: '/nutrition',
    name: 'nutrition',
    component: () => import('@/views/pages/RSPT/Nutrition/NutritionList.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Nutrition List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/views/pages/RSPT/Notification.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'My Profile',
          to:'/Admin/profile'
        },
        {
          text: 'Notification',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clientmanagement',
    name: 'clientmanagement',
    component: () => import('@/views/pages/RSPT/Client_Management.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text:'Home',
          to:'/dashboard',
          class:'breadcrumb-slash'
        },
        {
          text: 'Client Management',
          active:true,
          class: 'breadcrumb-slash'
        },
      ],
    },
  },
  {
    path: '/clientmanagement/:id',
    name: 'client-profile',
    component: () => import('@/views/pages/RSPT/Client_Profile.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Client Profile',
      breadcrumb: [
        {
          text:'Home',
          to:'/dashboard'
        },
        {
          text:"Client Management",
          to:'/clientmanagement'
        },
        {
          text: 'Overall Details',
          active:true
        },
      ],
      showButtons: false,
    },
  },
  {
    path: '/trainermanagement',
    name: 'trainermanagement',
    component: () => import('@/views/pages/RSPT/Trainer_Management.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Trainer Management',
          active:true
        },
      ],
    },
  },
  {
    path: '/trainermanagement/:id',
    name: 'Trainers Clients',
    component: () => import('@/views/pages/RSPT/Trainer-Client.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Trainer Clients',
      breadcrumb: [
        {
          text:'Home',
          to:'/dashboard'
      },
        {
          text:'Trainer Management',
          to:'/trainermanagement'
        },
        {
          text: 'Clients',
          active:true
        },
      ],
    },
  },
  {
    path: '/Plan-Renewal',
    name: 'Plan Renewal',
    component: () => import('@/views/pages//RSPT/PlanRenewable/PlanRenewable.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Plan Renewal Request',
      breadcrumb: [
        {
          text:'Home',
          to:'/dashboard'
      },
        {
          text:'Plan Renewal Request',
          active:true
        },
      ],
    },
  },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   component: () => import('@/views/pages/RSPT/AdminProfile.vue'),
  //   meta: {
  //     HideHomeIcon:true,
  //     pageTitle: 'Admin Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to:'/dashboard'
  //       },
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/pages/RSPT/AdminProfile.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Admin Profile',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainerprofile/:id',
    name: 'Trainers Profile',
    component: () => import('@/views/pages/RSPT/Trainer_Profile.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Trainer Profile',
      breadcrumb: [
        {
          text:'Home',
          to:'/dashboard'
      },
        {
          text:'Trainer Management',
          to:'/trainermanagement'
        },
        {
          text: 'Profile',
          active:true
        },
      ],
    },
  },
  {
    path: '/trainerprofile/:view/:id',
    name: 'Trainers Profile',
    component: () => import('@/views/pages/RSPT/Trainer_Profile.vue'),
    meta: {
      HideHomeIcon:true,
      pageTitle: 'Trainer Profile',
      breadcrumb: [
        {
          text:'Home',
          to:'/dashboard'
        },
        {
          text: 'Profile',
          active:true
        },
      ],
    },
  },
  {
    path: '/cms',
    name: 'cms',
    component: () => import('@/views/pages/RSPT/cmsPage/cmsmain.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'CMS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cms',
    name: 'cms',
    component: () => import('@/views/pages/RSPT/cmsPage/cmsmain.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'CMS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cms/addcms',
    name: 'add-cms',
    component: () => import('@/views/pages/RSPT/cmsPage/AddCms.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'CMS',
          to:'/cms',
        },
        {
          text: 'Add CMS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nutrition-approval',
    name: 'nutrtion-approval',
    component: () => import('@/views/pages/RSPT/Nutrition/NutritonApprove.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Nutrition',
          to:'/nutrition',
        },
        {
          text: 'Nutrition Approval',
          active: true,
        },
      ],
    },
  },
  {
    path: '/supplement-approval',
    name: 'supplement-approval',
    component: () => import('@/views/pages/RSPT/supplement/SupplementApprove.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'Suppelment',
          to:'/supplement',
        },
        {
          text: 'Supplement Approval',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cms/editcms/:id',
    name: 'edit-cms',
    component: () => import('@/views/pages/RSPT/cmsPage/AddCms.vue'),
    meta: {
      HideHomeIcon:true,
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Home',
          to:'/dashboard'
        },
        {
          text: 'CMS',
          to:'/cms',
        },
        {
          text: 'Edit CMS',
          active: true,
        },
      ],
    },
  }
  // {
  //   path: '/apps/e-commerce/:slug',
  //   name: 'apps-e-commerce-product-details',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
  //   meta: {
  //     pageTitle: 'Product Details',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //       {
  //         text: 'Product Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

]
