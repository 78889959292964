import Vue from 'vue'
// axios
import axios from 'axios';
// import '@/libs/sweet-alerts'
import store from '../store/app'

import VueSweetalert2 from 'vue-sweetalert2';

import router from '@/router'
import 'sweetalert2/dist/sweetalert2.min.css';
// Initialize a timer variable
Vue.use(VueSweetalert2);


const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: 'https://some-domain.com/api/',
    // timeout: 1000,
    headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') },
    // withCredentials: true,
})
// axiosIns.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axiosIns.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// Add a request interceptor
// axiosIns.interceptors.request.use(
//   config => {
//     // Modify the request config if needed
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )
// Add a response interceptor
axiosIns.interceptors.response.use(
    response => {

        // setTimer()
        // Return response if successful
        return response
    },
    error => {
        // Handle errors here
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.reload()
            }

            if (error.response.status == 403) {
                console.error(error.name, ':', error.response.data.message)
            }
            // console.log('Response Error1:', error.response.status, error.response.data)
        } else if (error.request) {
            // The request was made but no response was received
            // console.log('Request Error2:', error.request)
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('General Error3:', error.message)
        }
        return Promise.reject(error)
    }
)

Vue.prototype.$http = axiosIns

export default axiosIns
