import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter
import "@/@fake-db/db";
import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");
Vue.mixin({
  methods: {
    getOptions(name) {
      return this.$store.state.app[name];
    },
    getAllStoreData() {
      if (
        !this.$store.state.app.unit ||
        this.$store.state.app.unit?.length == 0
      ) {
        this.$store.dispatch("app/getUnit");
      }
      if (
        !this.$store.state.app.loseWeightData ||
        this.$store.state.app.loseWeightData?.length == 0
      ) {
        this.$store.dispatch("app/getLoseWeightData");
      }
      if (
        !this.$store.state.app.gainWeightData ||
        this.$store.state.app.gainWeightData?.length == 0
      ) {
        this.$store.dispatch("app/getGainWeightData");
      }
      // if (
      //   !this.$store.state.app.vidoecategories ||
      //   this.$store.state.app.vidoecategories?.length == 0
      // ) {
      //   this.$store.dispatch("app/addVideo");
      // }
      // if (
      //   !this.$store.state.app.customers ||
      //   this.$store.state.app.customers?.length == 0
      // ) {
      //   this.$store.dispatch("app/getAllCustomer");
      // }
      // if (
      //   !this.$store.state.app.trainers ||
      //   this.$store.state.app.trainers?.length == 0
      // ) {
      //   this.$store.dispatch("app/getAllTrainer");
      // }
      if (
        !this.$store.state.app.country ||
        this.$store.state.app.country?.length == 0
      ) {
        this.$store.dispatch("app/getCountry");
      }
      if (
        !this.$store.state.app.goal ||
        this.$store.state.app.goal?.length == 0
      ) {
        this.$store.dispatch("app/getGoal");
      }
      if (
        !this.$store.state.app.gender ||
        this.$store.state.app.gender?.length == 0
      ) {
        this.$store.dispatch("app/getGender");
      }

      if(
        !this.$store.state.app.specialized ||
        this.$store.state.app.specialized?.length == 0
      ){  
        this.$store.dispatch("app/getSpecialize");
      }
      if(

        !this.$store.state.app.language ||
        this.$store.state.app.language?.length == 0
      ){
        this.$store.dispatch("app/getLanguage");
      }
      if(

        !this.$store.state.app.plan ||
        this.$store.state.app.plan?.length == 0
      ){
        this.$store.dispatch("app/getPlan");
      }

      // if(
      //   !this.$store.state.app.category ||
      //   this.$store.state.app.category?.length == 0
      // ){
      //   this.$store.dispatch("app/getCategory");
      // }
    },
  },
});
Vue.filter('trim',(value,length=50)=>{
  if (value && value.length > length) {
      return value.substring(0, length) + '...'
  }
  return value
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
