import { $themeBreakpoints } from '@themeConfig'
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    country: [],
    specialized: [],
    trainers: [],
    gender: [],
    unit:[],
    plan: [],
    language: [],
    customers:[],
    vidoecategories:[],
    goal: [],
    category:[],
    adminData:[],
    loseWeightData:[],
    gainWeightData:[],
    loadGraph: false,
    lineAreaChartSpline:{
      chartOptions: {},
      series: [{ name: 'Customers', data: [] }, { name: 'Trainers', data: [] }],
    },
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getOptions: state => name => {
      return state[name]
    },
  },
  mutations: {
    SAVE_STATE(state){
      localStorage.setItem('store-data',JSON.stringify(state))
    },
    LOAD_STATE(state){
      let store_data = JSON.parse(localStorage.getItem('store-data'))
      if(store_data){        
        Object.assign(state,store_data)
        localStorage.removeItem('store-data')
      }
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    UPDATE_OPTIONS(state, payload) {
      state[payload.name] = payload.value
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_GRAPH_DATA(state, { customers, trainers, categories }) {
      state.lineAreaChartSpline.series[0].data = customers;
      state.lineAreaChartSpline.series[1].data = trainers;
      state.lineAreaChartSpline.chartOptions.xaxis.categories = categories;
    },
    SET_GRAPH_LOADING(state, loading) {
      state.loadGraph = loading;
    },
  },
  actions: {
    fetchGraphData({ commit, state }, graphData) {
      commit('SET_GRAPH_LOADING', true);

      let array = graphData.split(' to ');
      const customerData = {
        start_date: array[0],
        end_date: array[1],
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.accessToken}`,
        },
        url: `${state.baseApi}dashboard/get-graph-data`,
        data: JSON.stringify(customerData),
      };

      axios(requestOptions)
        .then(response => {
          commit('SET_GRAPH_DATA', {
            customers: response.data.data.map(item => item.customers),
            trainers: response.data.data.map(item => item.trainers),
            categories: response.data.data.map(item => moment(item.date).format('MM/DD')),
          });

          commit('SET_GRAPH_LOADING', false);

          // Schedule the next data fetch after 5 minutes
          // setTimeout(() => {
          //   dispatch('fetchGraphData', graphData);
          // }, 5 * 60 * 1000);
        })
        .catch(error => {
          console.error(error);
          commit('SET_GRAPH_LOADING', false);
        });
    },
    
    async getAllCustomer({commit}){
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}customer/all?page=1&per_page=10000`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'customers', value: json.data.data.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLoseWeightData({commit}){
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}weeklygoalloseweight/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'loseWeightData', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },

    async getGainWeightData({commit}){
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}weeklygoalgainweight/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'gainWeightData', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAdminData({commit}){
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      const user = JSON.parse(localStorage.getItem("userData"))
      
      await axios({
        method: "GET",
        url: `${baseApi}auth/admin/get-profile/${user.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'adminData', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAllTrainer({commit}){
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}trainer/all?page=1&per_page=10000`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'trainers', value: json.data.data.data })

        })
        .catch((error) => console.log(error, "error"));
    },
    async addVideo({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}workout/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'vidoecategories', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCountry({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}country/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'country', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getGoal({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}goal/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'goal', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getUnit({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}unit/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'unit', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCategory({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}workout/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'category', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getGender({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}gender/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'gender', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getSpecialize({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}specialized/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'specialized', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLanguage({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}language/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'language', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlan({commit}) {
      const baseApi = process.env.VUE_APP_APIENDPOINT
      const accessToken = localStorage.getItem('accessToken')
      await axios({
        method: "GET",
        url: `${baseApi}plan/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          commit('UPDATE_OPTIONS', { name: 'plan', value: json.data })
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};

// import { $themeBreakpoints } from '@themeConfig'
// import axios from 'axios';

// export default {
//   namespaced: true,
//   state: {
//     windowWidth: 0,
//     shallShowOverlay: false,
//     country:[],
//     specialized: [],
//     gender: [],
//     plan: [],
//     language: [],
//     goal: [],
//     masterInterval:null,
//     loadGraph: false,
//     lineAreaChartSpline: {
//       chartOptions: {}, 
//       series: [{ name: 'Customers', data: [] }, { name: 'Trainers', data: [] }],
//     },
//   },
//   getters: {
//     currentBreakPoint: state => {
//       const { windowWidth } = state
//       if (windowWidth >= $themeBreakpoints.xl) return 'xl'
//       if (windowWidth >= $themeBreakpoints.lg) return 'lg'
//       if (windowWidth >= $themeBreakpoints.md) return 'md'
//       if (windowWidth >= $themeBreakpoints.sm) return 'sm'
//       return 'xs'
//     },
//     getOptions: (state)=>(name) => {
//       return state[name]
//     }
//   },
//   mutations: {
//     UPDATE_WINDOW_WIDTH(state, val) {
//       state.windowWidth = val
//     },
//     UPDATE_OPTIONS(state, payload) {
//       state[payload.name] = payload.value
//     },
//     TOGGLE_OVERLAY(state, val) {
//       state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
//     },
//   },
//   actions: {},
// }
